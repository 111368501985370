import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@abyss/ui/base/Flex';
import { Tabs } from '@abyss/web/ui/Tabs';
import { PageLayout } from 'src/common/PageLayout/PageLayout';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { Loading } from '@abyss/ui/base/Loading';
import { styled, global } from '@abyss/ui/tools/styled';
import { config } from '@abyss/ui/tools/config';
import { ServiceSandbox } from 'src/common/ServiceSandbox';
import { animateScroll } from 'react-scroll';
import { event } from '@abyss/web/tools/event';
import { PageHeading } from './PageHeading/PageHeading';
import { useDocumentation } from '../../../../hooks/useDocumentation';
import { SideNav } from './SideNav';
import { APIDetails } from './APIDetails';
import { ErrorCodes } from './ErrorCodes';
import 'regenerator-runtime/runtime';

const INITIAL_CURRENT_API = {
  description: '',
  isDisabled: false,
  method: '',
  operationId: '',
  operationSlug: '',
  parameters: [],
  path: '',
  produces: [],
  responses: [],
  summary: '',
  tags: [],
};

const UpdatedSandboxStyle = global({
  '.abyss-table-cell': {
    wordBreak: 'unset !important',
  },
  '.abyss-box-root': {
    alignItems: 'flex-start',
  },
});

const StyledContent = styled(Flex.Content)({});

// gets definition if there is $ref for body
const getDefinition = (data, ref) => {
  const definition = ref.split('/').pop();
  return data.definitions[definition];
};

const getComponent = (data, ref) => {
  const component = ref.split('/').pop();
  return data.components.schemas[component];
};

const getParameters = (parameters, type) => {
  const filteredParameters = (parameters || []).filter(
    param => param.in === type
  );

  const formattedParameters = filteredParameters.map(param => {
    return {
      key: param.key,
      value: param.value,
    };
  });

  return formattedParameters;
};

const setupParams = params => {
  let paramObj = {};
  for (let i = 0; i < params.length; i++) {
    const element = params[i];
    if (element.value) {
      paramObj = {
        ...paramObj,
        [element.key]: element.value,
      };
    }
  }
  return paramObj;
};

export const Documentation = () => {
  const { service, slug } = useParams();
  const [currentApi, setCurrentApi] = useState(INITIAL_CURRENT_API);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [sandboxParameters, setSandboxParameters] = useState({
    pathurl: '',
    method: '',
    onSend: () => {},
    responses: [
      {
        response: '200',
        description: 'OK',
        header: [],
        query: [],
        parameters: [],
        body: {},
      },
    ],
    parameters: [],
  });
  const router = useRouter();

  const isNewSwagger = data => {
    return Object.keys(data).includes('openapi');
  };

  const getContentType = requestBody => {
    if (requestBody) {
      return Object.keys(requestBody.content)[0];
    }

    return null;
  };

  const getFormData = returnVal => {
    const bodyFormData = (returnVal.parameters || [])?.filter(
      param => param.in === 'formData'
    );
    let formData = bodyFormData;
    if (bodyFormData) {
      formData = bodyFormData.map(param => {
        return {
          key: param.name,
          description: param.description,
          value: param.enum ? param.enum[0] : '',
          type: param.type,
          required: param.required,
        };
      });
    }
    return formData;
  };

  const getV3FormData = (returnVal, parameters) => {
    const hasFormData = /post|put/i.test(returnVal.method);
    if (!hasFormData) {
      return null;
    }
    const contentType = getContentType(returnVal?.requestBody);
    if (contentType !== 'multipart/form-data') {
      return null;
    }
    const bodyParameter = returnVal.requestBody;
    const bodySchema = bodyParameter?.content[contentType].schema;
    const formData = [];
    if (bodySchema?.properties) {
      const bodyFormData = bodySchema?.properties || [];
      Object.keys(bodyFormData).forEach(key => {
        const param = bodyFormData[key];
        const required = bodySchema?.required?.includes(param);

        // openapi 3 uses type string and format as binary for files
        const type =
          param.type === 'string' && param.format === 'binary'
            ? 'file'
            : param.type;
        const obj = {
          key,
          description: param.description,
          value: param.enum ? param.enum[0] : '',
          type,
          required,
        };
        formData.push(obj);

        parameters.push({ ...obj, in: 'formData' });
      });
    }

    return formData;
  };

  const getV3SwaggerBody = (data, returnVal, parameters) => {
    const hasBody = /post|put/i.test(returnVal.method);
    if (!hasBody) {
      return null;
    }
    const contentType = getContentType(returnVal?.requestBody);
    if (contentType === 'multipart/form-data') {
      return null;
    }
    let bodySchema;
    if (contentType) {
      parameters.push({
        key: 'Content-Type',
        description: 'content type of request body',
        value: contentType,
        type: 'string',
        in: 'header',
      });
    }
    const bodyParameter = returnVal.requestBody;
    const apiBody = bodyParameter?.content[contentType];
    bodySchema = apiBody.schema;
    if (!bodySchema) {
      bodySchema = { example: null };
    }
    if (bodySchema?.$ref) {
      bodySchema = getComponent(data, bodySchema.$ref);
    }

    return apiBody.example || bodySchema.example;
  };

  const getBody = (data, returnVal) => {
    const hasBody = /post|put/i.test(returnVal.method);
    if (!hasBody) {
      return null;
    }
    let bodySchema;
    const bodyParameter = (returnVal.parameters || []).find(
      param => param.in === 'body'
    );
    bodySchema = bodyParameter?.schema;
    if (!bodySchema) {
      bodySchema = { example: null };
    }
    if (bodySchema?.$ref) {
      bodySchema = getDefinition(data, bodyParameter.schema.$ref);
    }
    return bodySchema.example;
  };

  const getParams = returnVal => {
    const parameterValue = returnVal?.parameters?.map(param => {
      return {
        in: param.in,
        key: param.name,
        description: param.description,
        value: param.enum ? param.enum[0] : '',
        type: param.type,
        required: param.required,
      };
    });
    return parameterValue;
  };

  const getV3Params = returnVal => {
    const parameterValue = returnVal?.parameters?.map(param => {
      return {
        in: param.in,
        key: param.name,
        description: param.description,
        value: param.schema?.enum ? param.schema.enum[0] : '',
        type: param.schema.type,
        required: param.required,
      };
    });
    return parameterValue;
  };
  const getV3PathUrl = (data, returnVal) => {
    const { path } = returnVal;
    const { servers } = data;
    return `${servers[0].url}${path}`;
  };

  const handleSendSuccess = (summary, method) => {
    const interactionName = `${service} ${summary}`;
    const interactionValue = 'send';
    const interactionContext = method;
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const getAllParameters = (newSwagger, returnVal) => {
    if (newSwagger) {
      return getV3Params(returnVal) || [];
    }
    return getParams(returnVal) || [];
  };

  // returns an object with API info for the API defined by the service and slug params
  const getCurrentApi = (data, slugParm) => {
    let returnVal;
    data.pathsByTag.forEach(listByTag => {
      listByTag.paths.forEach(path => {
        if (path.operationSlug === slugParm) {
          returnVal = path;
        }
      });
    });

    if (returnVal) {
      setCurrentApi(returnVal);
      if (slugParm) {
        event('DOCUMENTATION_PAGE_LOAD', {
          service,
          slug: slugParm,
          dsUserInfo,
          userInfo,
        });
      }

      const newSwagger = isNewSwagger(data);
      const parameters = getAllParameters(newSwagger, returnVal);

      if (!parameters.some(e => e.key === 'env')) {
        parameters.push({
          key: 'env',
          description: 'env',
          value: 'sandbox',
          type: 'string',
          required: false,
          in: 'header',
        });
      }

      const { method } = returnVal;
      const onSend = (url, options, callback) => {
        callback('Sandbox response for this API is not applicable.');
        handleSendSuccess(returnVal.summary, returnVal.method);
      };

      let body;
      let formData;
      let pathurl;
      if (newSwagger) {
        pathurl = getV3PathUrl(data, returnVal);
        body = getV3SwaggerBody(data, returnVal, parameters);
        formData = getV3FormData(returnVal, parameters);
      } else {
        const host = config('SANDBOX_API_URL');
        const { basePath } = data;
        const { path } = returnVal;
        pathurl = `${host}${basePath}${path}`;
        body = getBody(data, returnVal);
        formData = getFormData(returnVal);
      }

      const queryParameters = getParameters(parameters, 'query');
      const headerParameters = getParameters(parameters, 'header');
      const responses = [
        {
          response: '200',
          description: 'OK',
          parameters: {
            query: setupParams(queryParameters),
            header: setupParams(headerParameters),
            body,
            formData,
          },
        },
      ];

      const sandboxParameterObject = {
        pathurl,
        method,
        onSend,
        responses,
        parameters,
      };

      setSandboxParameters(sandboxParameterObject);
    }
  };

  // navigating first time to page doesn't know which path
  // so default to the first one.  If refreshing page there is
  // slug so use that.
  const handleCompleted = data => {
    if (data.pathsByTag && data.pathsByTag[0]) {
      const returnVal = { ...data.pathsByTag[0].paths[0] };
      router.replace(
        `/documentation/${data.service}/${slug || returnVal.operationSlug}`
      );
      getCurrentApi(data, slug || returnVal.operationSlug);
    }
  };

  const [documentation, getDocumentation] = useDocumentation({
    onCompleted: handleCompleted,
    onError: () => {
      setCurrentApi(INITIAL_CURRENT_API);
    },
  });
  const { data } = documentation;
  useEffect(() => {
    getDocumentation({ service });
  }, [service]);

  useEffect(() => {
    if (slug) {
      getCurrentApi(data, slug);
      animateScroll.scrollToTop();
    }
  }, [slug]);

  const handleTabChange = tab => {
    const interactionName = `${service} ${currentApi.method} ${currentApi.summary}`;
    const interactionValueList = ['api sandbox', 'api details', 'error codes'];
    const interactionValue = interactionValueList[tab];
    const interactionContext = 'tab clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  return (
    <PageLayout>
      <Flex>
        <Flex.Content space-25>
          <Loading
            label={service}
            isLoading={documentation.loading}
            isError={documentation.error}
          >
            <SideNav>
              <SideNav.Link
                service={service}
                data={data}
                currentTags={currentApi.tags}
              />
            </SideNav>
          </Loading>
        </Flex.Content>
        <StyledContent space-75>
          <Loading
            label={service}
            isLoading={documentation.loading}
            isError={documentation.error}
          >
            <div>
              <PageHeading
                info={data.info}
                currentApi={currentApi}
                service={service}
              />
              <Tabs
                onTabChange={handleTabChange}
                css={{
                  '.abyss-tabs-tab-content': { fontSize: '15px' },
                }}
              >
                <Tabs.Tab label="API Sandbox">
                  <UpdatedSandboxStyle />
                  <ServiceSandbox
                    url={sandboxParameters.pathurl}
                    method={sandboxParameters.method}
                    parameters={sandboxParameters.parameters}
                    responses={sandboxParameters.responses}
                    onSend={
                      currentApi.isDisabled || !!documentation.error
                        ? sandboxParameters.onSend
                        : null
                    }
                    onSuccess={() =>
                      handleSendSuccess(currentApi.summary, currentApi.method)
                    }
                    css={{
                      'abyss-code-highlighter-copy': {
                        position: 'absolute !important',
                      },
                      '.abyss-button-root': {
                        width: '150px',
                        height: '38px',
                      },
                    }}
                  />
                </Tabs.Tab>
                <Tabs.Tab label="API Details">
                  <APIDetails currentApi={currentApi} />
                </Tabs.Tab>
                <Tabs.Tab label="Error Codes">
                  <ErrorCodes currentApi={currentApi} />
                </Tabs.Tab>
              </Tabs>
            </div>
          </Loading>
        </StyledContent>
      </Flex>
    </PageLayout>
  );
};
