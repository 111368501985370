import React, { useState, useEffect } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { FormFields } from './FormFields';
import {
  resetLeadGenerationApi,
  useLeadGenerationApi,
} from '../../../../hooks/useLeadGenerationApi';
import {
  resetCalendarAPI,
  useCalendarApi,
} from '../../../../hooks/useCalendarApi';
import { useHolidayApi } from '../../../../hooks/useHolidayApi';
import { ErrorComponent } from '../../../../common/Error/Error';

const Wrapper = styled('div', {});
const ModalDescription = styled('p', {
  fontSize: '15px',
});

export const SubscribeToAPIModal = ({ showModal, modalOnClose }) => {
  const [, resetLeadGeneration] = resetLeadGenerationApi();
  const [leadGenerationApi, postLeadGenerationApi] = useLeadGenerationApi();
  const [, resetCalendarAPIResponse] = resetCalendarAPI();
  const [modalSize, setModalSize] = useState('lg');
  const [modalTitle, setModalTitle] = useState(
    'Request a meeting with an API consultant'
  );
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const form = useForm();
  const isFQHC = form.getValues('isFQHC');
  const [holidayDateApi, setHolidayDate] = useHolidayApi();
  const jobName = 'request a meeting with an api consultant';
  const modalEventName = 'meeting request with an api consultant modal';
  const [calendarDateApi] = useCalendarApi(state => state.GET_CALANDAR_TIMES);

  useEffect(() => {
    form.setValue('email', userInfo.email);
  }, [userInfo]);

  useEffect(() => {
    if (showModal) {
      resetLeadGeneration();
      const { data = {}, error } = holidayDateApi || {};
      if (!Object.keys(data)?.length || error) {
        setHolidayDate();
      }
      event('MODAL_VIEW', {
        event,
        modalName: modalEventName,
      });
    }
  }, [showModal]);

  const closeModalClicked = () => {
    modalOnClose(false);
    setDisplayError(false);
    resetLeadGeneration();
    resetCalendarAPIResponse();
    form.reset();
    form.setValue('email', userInfo.email);
    setDisplaySuccess(false);
    setModalSize('lg');
    setModalTitle('Request a meeting with an API consultant');

    event('MODAL_CLOSE', {
      event,
      modalName: modalEventName,
    });
    if (!leadGenerationApi.data) {
      event('JOB_CANCEL', {
        event,
        jobName,
      });
    }
  };

  const orgTypeMapping = {
    P: 'Care Provider',
    B: 'Revenue Cycle Management',
    V: 'Clearinghouse',
  };
  const errorMessage = 'Missing or invalid field(s)';
  const errorType = 'form';
  const onError = errors => {
    const errorFields = Object.keys(errors).join();
    event('FORM_ERROR', {
      event,
      errorMessage,
      errorFields,
      errorType,
    });
  };

  const onSubmit = data => {
    let fqhcConsultant;
    if (isFQHC === 'yes') {
      fqhcConsultant = calendarDateApi?.data?.consultants?.find(
        el => el.fqhcFlag === true
      );
    }
    const requestObj = {
      orgEmail: data.email,
      apis: data.selectedAPIs,
      phoneNo: data.phone,
      additionalComments: data.businessValue,
      orgName: data.orgName || dsUserInfo.orgName,
      orgType: data.orgType || orgTypeMapping[dsUserInfo?.orgType],
      preferredDate: data.preferredMeetingDate,
      preferredTime: data.preferredMeetingTime,
      preferredConsultantId:
        isFQHC === 'yes' ? fqhcConsultant?.consultantId : data.consultant,
      orgWebsite: data.orgWebsite || '',
      fqhc: isFQHC,
    };

    const requestedAPI = requestObj.apis.join();
    const organizationName = requestObj.orgName;
    const organizationType = requestObj.orgType;

    event('JOB_REQUEST_API_SUBSCRIPTION_SUBMIT', {
      event,
      jobName,
      requestedAPI,
      organizationName,
      organizationType,
    });

    postLeadGenerationApi({
      variables: { requestObj },
      userInfo,
    });
  };

  useEffect(() => {
    if (
      !leadGenerationApi.loading &&
      leadGenerationApi.data?.status &&
      leadGenerationApi.data?.status === 200 &&
      showModal
    ) {
      event('JOB_COMPLETE', {
        event,
        jobName,
      });
      setModalSize('md');
      setDisplaySuccess(true);
      setModalTitle('Thank you for your submission');
    }
    if (leadGenerationApi?.error) {
      setDisplayError(true);
      const leadGenerationErrorMessage = leadGenerationApi?.data?.message || '';
      if (leadGenerationErrorMessage) {
        setServerErrorMessage(leadGenerationErrorMessage);
      } else {
        setServerErrorMessage(
          'Your request was unsuccessful, please try again.'
        );
      }
    }
  }, [leadGenerationApi]);

  return (
    <Wrapper>
      <Modal
        size={modalSize}
        title={modalTitle}
        isOpen={showModal}
        onClose={closeModalClicked}
        closeOnClickOutside={false}
        css={{
          'abyss-modal-content-title': { 'font-size': '25px !important' },
        }}
      >
        <Choose>
          <When condition={displaySuccess}>
            <Modal.Section>
              <ModalDescription id="reminder">
                Please be sure to set your reminder, we look forward to meet
                with you on your selected Date and Time.
              </ModalDescription>
            </Modal.Section>
          </When>
          <Otherwise>
            <Modal.Section>
              <ModalDescription id="APISubscription">
                Please complete the form below to provide us with information
                about your organization and the APIs of interest. An API
                Consultant will meet with you on your selected date and time.
              </ModalDescription>
              <FormProvider state={form} onSubmit={onSubmit} onError={onError}>
                <FormFields />
                <Layout.Group alignLayout="right">
                  <Button
                    variant="outline"
                    onClick={closeModalClicked}
                    style={{
                      marginTop: '15px',
                      marginRight: '15px',
                      fontSize: '15px',
                    }}
                    id="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={leadGenerationApi.loading}
                    ariaLoadingLabel="Request a meeting with an API consultant"
                    type={leadGenerationApi.loading ? 'button' : 'submit'}
                    style={{ marginTop: '15px', fontSize: '15px' }}
                    id="requestNow"
                  >
                    Request Now
                  </Button>
                </Layout.Group>
                <Layout.Stack alignLayout="right">
                  {displayError ? (
                    <ErrorComponent id="serverError">
                      {serverErrorMessage}
                    </ErrorComponent>
                  ) : null}
                </Layout.Stack>
              </FormProvider>
            </Modal.Section>
          </Otherwise>
        </Choose>
      </Modal>
    </Wrapper>
  );
};

SubscribeToAPIModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalOnClose: PropTypes.func.isRequired,
};
